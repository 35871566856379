import { React, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import ContentPopup from "./ContentPopup";

// import {assignmentCheck} from "@iconify-icons/zmdi/assignment-check";
import { useNavigate } from "react-router-dom";
import logo from "../Images/logo-new.png";
import thankYou from "../Images/thank-you.png";

import { Helmet } from "react-helmet";

function Thankyou(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Thank You";
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  var redemptionForm = secureLocalStorage.getItem("UserForm");

  var redemptionObject = redemptionForm ? JSON.parse(redemptionForm) : false;

  const [showPopup, setShowPopup] = useState(redemptionObject.popup);

  // FIXME
  localStorage.removeItem("returnthankyou", true);
  localStorage.removeItem("voucher", "");
  localStorage.removeItem("returnpage", false);
  localStorage.removeItem("userformopen");
  localStorage.removeItem("userform");

  const isVoucher = localStorage.getItem("Thankyou");
  const Voucher = localStorage.getItem("voucher");

  useEffect(() => {
    if (redemptionObject === false) {
      navigate("/");
    }
    if (isVoucher !== "true" && !Voucher) {
      localStorage.setItem("isVoucher", false);

      navigate("/");
    } else {
      localStorage.setItem("Thankyou", false);
      setShow(true);
    }
  }, []);

  // StorageService.destroyRedThankStorage();

  // const isVoucher = StorageService.getThankStatus();
  // const Voucher = StorageService.getVoucher();
  // if (
  //   isVoucher !== "true" ||
  //   Voucher === undefined ||
  //   props.history.location.zipcode === "undefined"
  // ) {
  //   StorageService.setVoucherStatus(false);
  //   return <Redirect to="/" />;
  // } else {
  //   StorageService.setThankStatus(false);
  // }
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="redem-home">
        <div className="voucher-section">
          <div className="voucher-entry">
            <img src={logo} alt="logo..." className="logo-redem" />
            <div className="read-carefully thank">
              Congratulations on your redemption.
            </div>
            <h4 className="thank-head">
              {process.env.REACT_APP_THANKYOUTITLE}
            </h4>
            <img src={thankYou} alt="" className="img-fluid thank-img" />
          </div>
        </div>
        <div className="infor-section thankyou">
          <ul>
            <li className="active">
              <div className="info-text">
                <h6>
                  {process.env.REACT_APP_THANKYOUTITLE}{" "}
                  <span>
                    {redemptionObject.name} {redemptionObject.lname}
                  </span>
                </h6>
                <div className="userinfo-box">
                  <div className="user-forminfo">
                    <span>Address:</span> {redemptionObject.address}
                  </div>
                  <div className="user-forminfo">
                    <span>City/Zip:</span> {redemptionObject.city}/
                    {redemptionObject.zipcode}
                  </div>
                  <div className="user-forminfo">
                    <span>State:</span> {redemptionObject.state}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="info-text">
                Expect an email(s) within 5 business days from{" "}
                <a href={"mailto:" + process.env.REACT_APP_DESC_REWARD}>
                  {process.env.REACT_APP_DESC_REWARD}
                </a>
                ,{" "}
                <a href={"mailto:" + process.env.REACT_APP_PREP}>
                  {process.env.REACT_APP_PREP}
                </a>{" "}
                or{" "}
                <a href={"mailto:" + process.env.REACT_APP_MARKET_PLACE}>
                  {process.env.REACT_APP_MARKET_PLACE}
                </a>
                .
              </div>
            </li>
            <li>
              <div className="info-text">
                Please add these email addresses to your safe senders list in
                your email setting.
              </div>
            </li>
            <li>
              <div className="info-text">
                Once you receive your email(s) you may use the reward virtually
                or request plastic cards to be mailed.
              </div>
            </li>
            <li>
              <div className="info-text">
                You will need to click that link to finalize your order and
                select your preferred delivery method.
              </div>
            </li>
            <li>
              <div className="info-text">
                Please do not discard your certificate until you receive your
                reward card.
              </div>
            </li>

            <li>
              <div className="info-text">
                If you have any questions, please call{" "}
                <a href={"tel:" + process.env.REACT_APP_DESC_CONTACTUS + '"'}>
                  {process.env.REACT_APP_DESC_CONTACTUS}
                </a>{" "}
                or email us at{" "}
                <a
                  href={
                    "mailto:" + process.env.REACT_APP_DESC_CUSTOMERCARE + '"'
                  }
                >
                  {process.env.REACT_APP_DESC_CUSTOMERCARE}
                </a>
                <span>
                  and one of our reward card specialists will get back to you.
                </span>
                <h6 className="mt-4">Enjoy your card!</h6>
              </div>
            </li>
            <li className="pb-0">
              <div className="info-text">
                <strong>PLEASE NOTE:</strong> If the card you have selected is
                out of stock, we will send you a Mastercard Reward Card instead,
                which can be used at thousands of retailers instead of just one
                place!
              </div>
            </li>
            <div className="new-footer">
              <p>
                © Copyright {new Date().getFullYear()}. All rights reserved.{" "}
                {process.env.REACT_APP_API_SITE_TITLE}.
              </p>
            </div>
          </ul>
        </div>
        <div className="clearfix"></div>
      </div>

      {showPopup === "true" && <ContentPopup />}
      <div className={`fix-white ${show ? "hide" : ""}`}></div>
    </>
  );
}
export default Thankyou;
