import { React, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Helmet } from "react-helmet";
import logo from "../Images/logo-new.png";

function CertDetails(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Certificate Details";
  const isVoucher = localStorage.getItem("cert_details");

  var cert_details = secureLocalStorage.getItem("cert_details");
  var RedemObject = JSON.parse(cert_details);
  const navigate = useNavigate();

  useEffect(() => {
    if (isVoucher !== "true") {
      navigate("/");
    } else {
      localStorage.setItem("cert_details", false);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="redem-home">
        <div className="voucher-section">
          <div className="voucher-entry">
            <img src={logo} alt="logo..." className="logo-redem" />
            <div className="read-carefully thank">
              Reward Card Redemption Site.
            </div>
            <h4 className="thank-head ps-0">
              {RedemObject?.cardName ? RedemObject.cardName : ""}
            </h4>
            <div className="cert-detail-img">
              {RedemObject?.cardImage ? (
                <img
                  src={process.env.REACT_APP_API_IMAGES + RedemObject.cardImage}
                  alt={RedemObject.cardImage}
                  className="img-fluid thank-img"
                />
              ) : (
                <div className="pirds pirds2">
                  <span className="rdcrdtop">
                    <table border="0">
                      <tr>
                        <td>
                          {RedemObject?.cardName ? RedemObject.cardName : ""}
                        </td>
                      </tr>
                    </table>
                  </span>
                  <span className="rdcrd">REWARD CARD</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="infor-section thankyou">
          <ul>
            <li className="active">
              <div className="info-text">
                <h6>Contact Information</h6>
              </div>
            </li>

            <li>
              <div className="info-text">
                <div className="userinfo-box certi-info-box">
                  <div className="user-forminfo">
                    <span>First Name:</span>{" "}
                    {RedemObject?.fname ? RedemObject.fname : ""}
                  </div>
                  <div className="user-forminfo">
                    <span>Last Name:</span>{" "}
                    {RedemObject?.lname ? RedemObject.lname : ""}
                  </div>

                  <div className="user-forminfo">
                    <span>Address:</span>{" "}
                    {RedemObject.address ? RedemObject.address : ""}
                  </div>
                  <div className="user-forminfo">
                    <span>City:</span>{" "}
                    {RedemObject.city ? RedemObject.city : ""}
                  </div>
                  <div className="user-forminfo">
                    <span>State:</span>{" "}
                    {RedemObject.state ? RedemObject.state : ""}
                  </div>
                  <div className="user-forminfo">
                    <span>Zip Code:</span>{" "}
                    {RedemObject.zip ? RedemObject.zip : ""}
                  </div>
                  <div className="user-forminfo">
                    <span>Phone No:</span>{" "}
                    {RedemObject.phone ? RedemObject.phone : ""}
                  </div>
                  <div className="user-forminfo">
                    <span>Email:</span>{" "}
                    {RedemObject.email ? RedemObject.email : ""}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="info-text">
                <h6>{process.env.REACT_APP_NAME}</h6>
              </div>
            </li>
            <li>
              <div className="info-text buton-li">
                <Link to="/" className="back-certi">
                  Go Back
                </Link>
              </div>
            </li>

            <div className="new-footer">
              <p>
                © Copyright {new Date().getFullYear()}. All rights reserved.{" "}
                {process.env.REACT_APP_API_SITE_TITLE}.
              </p>
            </div>
          </ul>
        </div>
        <div className="clearfix"></div>
      </div>
    </>
  );
}
export default CertDetails;
