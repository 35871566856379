import React, { useEffect, useRef, useState } from "react";
import "./singleCSS.css";
import Logo from "./images/with-customer-form_02.png";
import fileImg from "./images/file.png";
import browse from "./images/icon-4.png";
import edit from "./images/icon-5.png";
import del from "./images/icon-6.png";

import InnerHTML from "dangerously-set-html-content";
import profileIco from "./images/logout.png";
import logOutIco from "./images/icon-02.png";
import $ from "jquery";
import AdminListService from "../../../services/admin-list.service";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { customerAddNew, profile } from "../../../schema";
import { useFormik } from "formik";
import Pagination from "react-js-pagination";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import "select2/dist/css/select2.min.css";
import "select2";
import NewOsrIndex from "./NewOsrIndex";
import NewAccountantIndex from "./NewAccountantIndex";
import { useMatch, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux";
import NewNewsletter from "./NewNewsletter";

export default function NewAdmin() {
  const state = useSelector((state) => state.stateVals);
  const { customerAccessToken, customer_id, customerSiteId } = state;
  const [pageLoading, setPageLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);

  const [loading, setLoading] = useState("0%");
  const [duplicateMsg, setduplicateMsg] = useState("");
  const [errorData, setErrorData] = useState([]);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [fileError, setFileError] = useState(false);
  const [StatesList, setStatesList] = useState([]);
  const [StatesListCopy, setStatesListCopy] = useState([]);
  const [UserData, setUserData] = useState([]);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("20");
  const [file, setFile] = useState("");
  const [fileStatus, setFileStatus] = useState("");
  const [emailError, setEmailError] = useState("");
  const [EmailChecking, setEmailChecking] = useState(false);
  const [EditId, setEditId] = useState("");
  const [EditType, setEditType] = useState("");
  const [voucherError, setVoucherError] = useState("");
  const [editGroupNo, setEditGroupNo] = useState("");

  const [isEditable, setisEditable] = useState(false);

  const selectRef = useRef();
  const match = useMatch("/3in1Step", { caseSensitive: false });

  const getUsers = async (page, load) => {
    try {
      setCustomerLoading(true);
      const responce = await AdminListService.getCustomersData(
        customer_id,
        page
      );

      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            UserId: value.UserId,
            FirstName: value.FirstName,
            LastName: value.LastName,
            City: value.City,
            State: value.State,
            ZipCode: value.ZipCode,
            PhoneNumber: value.PhoneNumber,
            Address: value.Address,
            Email: value.Email,
            Denomination: value.Denomination,
            voucher: value.voucher,
            clientcost: value.clientcost,
            invoice: value.invoice,
            date_created: value.date_created,
          });
        });

        setUserData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);
        setCustomerLoading(false);
        setTimeout(() => {
          if (!load) {
            $("html, body").animate(
              {
                scrollTop: $("#customer-list-data").offset().top,
              },
              0
            );
          }
        }, 500);
      }
    } catch (err) {
      console.log(err);
      setUserData([]);
      setCustomerLoading(false);
    }
  };

  const getStatesNames = async () => {
    try {
      const responce = await AdminListService.getStates();

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        return results.push({
          value: value.code,
          label: value.state_name,
        });
      });
      setStatesList([...results]);
      setStatesListCopy([...results]);
    } catch (err) {
      console.log(err);
      setStatesList([]);
    }
  };

  const putCustomers = async (event) => {
    setFileStatus("");

    if (!event.target.files?.length) {
      setFileError(true);
      setLoading("0%");
    } else {
      setFile(event.target.files[0]);
      setFileError(false);
      await setLoading(`0%`);
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("fileName", event.target.files[0].name);
      formData.append("AdminId", customer_id);
      formData.append("sessionId", customer_id);

      try {
        let randomNum = Math.floor(Math.random() * 10);
        await setLoading(`${randomNum}%`);

        await setTimeout(() => {
          const randomNum = Math.floor(Math.random() * (20 - 10 + 1)) + 10;
          setLoading(`${randomNum}%`);
        }, 200);
        await setTimeout(() => {
          const randomNum = Math.floor(Math.random() * (40 - 20 + 1)) + 20;
          setLoading(`${randomNum}%`);
        }, 400);

        await setTimeout(() => {
          const randomNum = Math.floor(Math.random() * (70 - 40 + 1)) + 40;
          setLoading(`${randomNum}%`);
        }, 500);

        const response = await AdminListService.uploadCustomers(formData);

        await setTimeout(() => {
          if (response.status === 200) {
            setErrorData([]);
            setduplicateMsg("");
            setInvalidEmails([]);

            setLoading("100%");
            toast.success("Uploaded Successfully!", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setTimeout(() => {
              setFileStatus("ok");
              getUsers("1");
            }, 500);
          }
        }, 200);
      } catch (err) {
        console.log(err);

        await setTimeout(() => {
          const randomNum = Math.floor(Math.random() * (80 - 70 + 1)) + 70;
          setLoading(`${randomNum}%`);
        }, 200);
        await setTimeout(() => {
          setLoading("100%");
        }, 500);

        await setTimeout(() => {
          if (
            err?.response?.data?.data?.data?.invalidEmails?.invalidEmail
              .length &&
            Array.isArray(
              err?.response?.data?.data?.data?.invalidEmails?.invalidEmail
            )
          ) {
            const errors = [];

            err?.response?.data?.data?.data?.invalidEmails?.invalidEmail.map(
              (value) => {
                return errors.push({
                  row: value.row,
                  errors: value,
                });
              }
            );

            setInvalidEmails([...errors]);

            toast.error("Invalid Emails Found!", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setTimeout(() => {
              getUsers("1");
            }, 1500);
          } else {
            setInvalidEmails([]);

            toast.error("Error in uploading customers", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setTimeout(() => {
              getUsers("1");
            }, 1500);
          }
          if (err?.response?.data?.data?.data) {
            if (
              err?.response?.data?.data?.data?.failure !== "" &&
              err?.response?.data?.data?.data?.duplicate === ""
            ) {
              if (Array.isArray(err?.response?.data?.data?.data?.failure)) {
                const errors = [];

                err?.response?.data?.data?.data?.failure.map((value) => {
                  return errors.push({
                    row: value.row,
                    errors: value.errors.toString().replaceAll("_", " "),
                  });
                });

                setErrorData([...errors]);
                setduplicateMsg("");
              }
            } else {
              setduplicateMsg(err?.response?.data?.data?.data?.message);
              setErrorData([]);
            }
          } else if (err?.response?.data) {
            if (err?.response?.data?.failure !== "") {
              if (Array.isArray(err?.response?.data?.failure)) {
                setduplicateMsg(err?.response?.data?.failure[0]);
              }
            }
          }

          setFileStatus("err");
        }, 700);
      }
    }
  };

  const Duplicate = ({ data }) => {
    return (
      <>
        {data !== "" ? (
          <div className="alert alert-danger  mb-2 p-2 rounded-1">
            <h5 className="m-0 fw-bold">Error!</h5>{" "}
            <InnerHTML html={data ? data : ""} />
          </div>
        ) : null}
      </>
    );
  };

  // const varifyEmail = async (e) => {
  //   setEmailError("");
  //   if (e.target.value !== "") {
  //     setEmailChecking(true);
  //     let response;
  //     try {
  //       if (isEditable) {
  //         response = await AdminListService.updateCustomerEmailNew(
  //           e.target.value,
  //           EditId,

  //           EditType
  //         );
  //       } else {
  //         response = await AdminListService.checkCustomerEmailNew(
  //           e.target.value,

  //           "customer"
  //         );
  //       }
  //       if (response.status === 200) {
  //         setEmailError("");
  //         setEmailChecking(false);
  //       }
  //     } catch (err) {
  //       if (err.response?.status === 409) {
  //         setEmailError("Email already exists");
  //         setEmailChecking(false);
  //       }
  //     }
  //   }
  // };

  const voucherCheck = async (e) => {
    if (e !== "" && e.length >= 5) {
      setEmailChecking(true);
      setVoucherError("Checking");
      setFieldError("voucher", "Checking");
      let response;
      try {
        if (isEditable) {
          if (EditType === "customer") {
            response =
              await AdminListService.updateCustomerCheckDuplicateVoucher(
                e,
                EditId
              );
          } else if (EditType === "osr") {
            response = await AdminListService.updateOsrCheckDuplicateVoucher(
              e,
              EditId,
              editGroupNo
            );
          } else if (EditType === "accountant") {
            response =
              await AdminListService.updateAccountantCheckDuplicateVoucher(
                e,
                EditId,
                editGroupNo
              );
          }
        } else {
          response = await AdminListService.checkDuplicateVoucher(e);
        }
        if (response.status === 200) {
          setFieldError("voucher", "");
          setVoucherError("");
          setTimeout(() => {
            setEmailChecking(false);
          }, 500);
        }
      } catch (err) {
        if (err.response?.status === 409) {
          setVoucherError("Voucher already exists");
          setFieldError("voucher", "Voucher already exists");
          setTimeout(() => {
            setEmailChecking(false);
          }, 500);
        }
      }
    }
  };

  const delDataAll = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",

      allowOutsideClick: false,
      showClass: {
        popup: `del-pop  
        animate__animated
        animate__fadeInUp
        animate__faster
        
        `,
        icon: "ques-ico",
      },
      hideClass: {
        popup: `
          del-pop  
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setCustomerLoading(true);
        setErrorData([]);
        setInvalidEmails([]);
        setduplicateMsg("");
        setLoading("0%");
        try {
          const response = await AdminListService.deleteCustomersDataAll(
            customer_id
          );
          if (response.status === 200) {
            toast.success("Deleted Successfully!", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            setTimeout(() => {
              getUsers("1");
            }, 1500);
          }
        } catch (err) {
          toast.error("Something went wrong!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setCustomerLoading(false);
        }
      }
    });
  };

  const delData = async (delId, type) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",

      allowOutsideClick: false,
      showClass: {
        popup: `del-pop  
        animate__animated
        animate__fadeInUp
        animate__faster
        
        `,
        icon: "ques-ico",
      },
      hideClass: {
        popup: `
          del-pop  
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (type === "customer") {
          setCustomerLoading(true);
          setLoading("0%");
          setErrorData([]);
          setInvalidEmails([]);
          setduplicateMsg("");
        } else if (type === "osr") {
          setOsrLoading(true);
        } else if (type === "accountant") {
          setAccountantLoading(true);
        }

        try {
          const response = await AdminListService.deleteCustomersDataNew(
            delId,
            type
          );
          if (response.status === 200) {
            toast.success("Deleted Successfully!", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            setTimeout(() => {
              if (type === "customer") {
                getUsers(currentPage);
              } else if (type === "osr") {
                getOsr();
              } else if (type === "accountant") {
                getAccountant();
              }
            }, 500);
          }
        } catch (err) {
          toast.error("Something went wrong!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          if (type === "customer") {
            setCustomerLoading(false);
          } else if (type === "osr") {
            setOsrLoading(false);
          } else if (type === "accountant") {
            setAccountantLoading(true);
          }
        }
      }
    });
  };

  const delSelected = async () => {
    var selectedRecords = $(".table tbody tr td input:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");

    if (!selectedRecords.length) {
      toast.error("Please select some record!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete record",
        icon: "question",
        iconColor: "#e04f5d",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",

        allowOutsideClick: false,
        showClass: {
          popup: `del-pop  
          animate__animated
          animate__fadeInUp
          animate__faster
          
          `,
          icon: "ques-ico",
        },
        hideClass: {
          popup: `
            del-pop  
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setCustomerLoading(true);
          setErrorData([]);
          setInvalidEmails([]);
          setduplicateMsg("");
          setLoading("0%");
          try {
            const response = await AdminListService.deleteSelectedCustomersData(
              selectedRecords,
              customerSiteId,
              customer_id
            );
            if (response.status === 200) {
              toast.success("Deleted Successfully!", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setTimeout(() => {
                getUsers("1");
              }, 1500);
            }
          } catch (err) {
            toast.error("Something went wrong!", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            setCustomerLoading(false);
          }
        }
      });
    }
  };

  const submitCustomerPop = async () => {
    try {
      setCustomerLoading(true);

      const response = await AdminListService.submitFinalCustomers(
        customer_id,
        customerSiteId
      );
      if (response.status === 200) {
        setisEditable(false);
        resetForm();
        setErrorData([]);
        setduplicateMsg("");
        setInvalidEmails([]);
        toast.success("Submitted Successfully!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(() => {
          getUsers("1");
        }, 500);
        setTimeout(() => {
          getOsr();
        }, 1000);
      }
    } catch (err) {
      setCustomerLoading(false);
      toast.error("Something went wrong!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const addCustomer = async (values, action) => {
    if (emailError === "" && voucherError === "") {
      try {
        setErrorData([]);
        setInvalidEmails([]);
        setduplicateMsg("");
        setEmailChecking(true);

        const response = await AdminListService.addCustomerNew(
          values,
          customer_id,
          customerAccessToken
        );
        if (response.status === 200) {
          action.resetForm();
          setEmailChecking(false);

          await setStatesList([]);
          toast.success("Added Successfully!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setTimeout(() => {
            setStatesList(StatesListCopy);
            getUsers("1");
          }, 1500);
        }
      } catch (err) {
        console.log(err);
        setEmailChecking(false);
        if (err?.response?.status === 422) {
          toast.error("Some data missing!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          let errorData = {};
          errorData["fname"] =
            err.response?.data?.message &&
            err.response?.data?.message?.fname &&
            err.response?.data?.message?.fname[0];
          errorData["lname"] =
            err.response?.data?.message &&
            err.response?.data?.message?.lname &&
            err.response?.data?.message?.lname[0];
          errorData["address"] =
            err.response?.data?.message &&
            err.response?.data?.message?.address &&
            err.response?.data?.message?.address[0];
          errorData["city"] =
            err.response?.data?.message &&
            err.response?.data?.message?.city &&
            err.response?.data?.message?.city[0];
          errorData["state"] =
            err.response?.data?.message &&
            err.response?.data?.message?.state &&
            err.response?.data?.message?.state[0];
          errorData["zip"] =
            err.response?.data?.message &&
            err.response?.data?.message?.zip &&
            err.response?.data?.message?.zip[0];
          errorData["phone"] =
            err.response?.data?.message &&
            err.response?.data?.message?.phone &&
            err.response?.data?.message?.phone[0];
          errorData["email"] =
            err.response?.data?.message &&
            err.response?.data?.message?.email &&
            err.response?.data?.message?.email[0];

          errorData["voucher"] =
            err.response?.data?.message &&
            err.response?.data?.message?.voucher &&
            err.response?.data?.message?.voucher[0];
          setErrors(errorData);
        } else {
          toast.error("Something went wrong!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };

  const getCustomerWithId = async (id, type, groupNo) => {
    setEditType(type);
    setVoucherError("");
    if (type !== "customer") {
      setEditGroupNo(groupNo);
    } else {
      setEditGroupNo("");
    }
    try {
      setPageLoading(true);

      const response = await AdminListService.getCustomersDataByIdNew(id, type);

      if (response.status === 200) {
        setEditId(response.data.data.id);
        setValues({
          fname: response.data.data.fname,
          lname: response.data.data.lname,
          address: response.data.data.address,
          city: response.data.data.city,
          state: response.data.data.state,
          zip: response.data.data.zip,
          email: response.data.data.email,
          phone: response.data.data.phone ? response.data.data.phone : "",
          denomination: response.data.data.denomination,
          voucher: response.data.data.voucher,
        });

        // const index = StatesList.findIndex(
        //   (item) => item.value === response.data.data.state
        // );
        setisEditable(true);

        await setStatesList([]);

        setTimeout(() => {
          setStatesList(StatesListCopy);
          $("html, body").animate(
            {
              scrollTop: $("#time-1").offset().top,
            },
            0
          );
        }, 100);
        setPageLoading(false);
      }
    } catch (err) {
      setPageLoading(false);
      setisEditable(false);
      toast.error("Something went wrong!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const UpdateCustomer = async (values, action) => {
    if (emailError === "" && voucherError === "") {
      try {
        setEmailChecking(true);

        const response = await AdminListService.updateCustomerNew(
          values,
          customer_id,
          EditId,
          customerAccessToken,
          EditType
        );

        if (response.status === 200) {
          setEmailChecking(false);
          action.resetForm();
          setEditId("");

          setisEditable(false);
          toast.success("Updated Successfully!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          await setStatesList([]);
          setTimeout(() => {
            setStatesList(StatesListCopy);
            if (EditType === "customer") {
              getUsers(currentPage);
            }

            setEditType("");
          }, 1500);
        }
      } catch (err) {
        setEmailChecking(false);
        if (err?.response?.status === 422) {
          toast.error("Some data missing!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          let errorData = {};
          errorData["fname"] =
            err.response?.data?.message &&
            err.response?.data?.message?.fname &&
            err.response?.data?.message?.fname[0];
          errorData["lname"] =
            err.response?.data?.message &&
            err.response?.data?.message?.lname &&
            err.response?.data?.message?.lname[0];
          errorData["address"] =
            err.response?.data?.message &&
            err.response?.data?.message?.address &&
            err.response?.data?.message?.address[0];
          errorData["city"] =
            err.response?.data?.message &&
            err.response?.data?.message?.city &&
            err.response?.data?.message?.city[0];
          errorData["state"] =
            err.response?.data?.message &&
            err.response?.data?.message?.state &&
            err.response?.data?.message?.state[0];
          errorData["zip"] =
            err.response?.data?.message &&
            err.response?.data?.message?.zip &&
            err.response?.data?.message?.zip[0];
          errorData["phone"] =
            err.response?.data?.message &&
            err.response?.data?.message?.phone &&
            err.response?.data?.message?.phone[0];
          errorData["email"] =
            err.response?.data?.message &&
            err.response?.data?.message?.email &&
            err.response?.data?.message?.email[0];

          errorData["voucher"] =
            err.response?.data?.message &&
            err.response?.data?.message?.voucher &&
            err.response?.data?.message?.voucher[0];
          setErrors(errorData);
        } else {
          toast.error("Something went wrong!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };

  const allSelect = async () => {
    $("input:checkbox").prop("checked", $(".slect_all").prop("checked"));
  };

  const {
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
    setFieldError,
    setValues,
    setErrors,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      phone: "",
      denomination: "",
      voucher: "",
    },
    validationSchema: customerAddNew,
    onSubmit: (values, action) => {
      if (isEditable) {
        UpdateCustomer(values, action);
      } else {
        addCustomer(values, action);
      }
    },
  });

  useEffect(() => {
    if (match?.pattern?.path === match?.pathname) {
      if (customer_id) {
        getUsers("1", "load");
        getStatesNames();

        const $select = $(selectRef.current);

        $select.select2();

        $select.on("change", (event) => {
          const selectedValue = $(event.target).val();
          setFieldValue("state", selectedValue);
        });

        return () => {
          $select.select2("destroy");
          $select.off("change");
        };
      }
    } else {
      navigate("/3in1Step", { replace: true });
    }
  }, []);

  // osr apis call and states from here
  const [osrData, setOsrData] = useState([]);
  const [osrLoading, setOsrLoading] = useState(false);
  const getOsr = async (load) => {
    setOsrLoading(true);
    try {
      const responce = await AdminListService.getOsrData();

      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            Sitname: value.Sitename,
            submited: value.submitted,
            Participants: value.Participants,
            jobNo: value.jobNo,
            bulk: value.bulk,

            SiteId: value.SiteId,
          });
        });

        setOsrData([...results]);
        if (res?.length) {
          $("#time-1").addClass("complete");

          setTimeout(() => {
            $("#time-2").addClass("active");
            if (!load) {
              $("html, body").animate(
                {
                  scrollTop: $("#time-2").offset().top - 30,
                },
                0
              );
            }
            if (!accountantData.length) {
              getAccountant("load");
            } else {
              $("#time-2").addClass("complete");
            }
          }, 100);
        }

        setOsrLoading(false);
      }
    } catch (err) {
      console.log(err);
      setOsrData([]);
      setOsrLoading(false);
      $("#time-2").removeClass("active");
      $("#time-1").removeClass("complete");

      if (!accountantData.length) {
        getAccountant("load");
      } else {
        $("#time-1").addClass("complete");
      }
    }
  };

  // accountant apis call and states from here
  const [accountantData, setAccountantData] = useState([]);
  const [accountantLoading, setAccountantLoading] = useState(false);
  const getAccountant = async (load) => {
    setAccountantLoading(true);
    try {
      const responce = await AdminListService.getAccountantData();

      let res;

      if (responce.status === 200) {
        res = responce.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            Sitname: value.Sitname,
            submited: value.submited,
            Participants: value.Participants,
            totalCount: value.totalCount,

            jobNo: value.jobNo,
            bulk: value.bulk,
          });
        });

        setAccountantData([...results]);
        if (res?.length) {
          if (!$("#time-2").hasClass("active")) {
            $("#time-1").addClass("complete");
          } else {
            $("#time-2").addClass("complete");
          }

          setTimeout(() => {
            $("#time-3").addClass("active");

            if (!load) {
              $("html, body").animate(
                {
                  scrollTop: $("#time-3").offset().top - 30,
                },
                0
              );
            }
          }, 100);
        }
        setAccountantLoading(false);
      }
    } catch (err) {
      console.log(err);
      setAccountantData([]);
      setAccountantLoading(false);
      if (!$("#time-2").hasClass("active")) {
        $("#time-1").removeClass("complete");
      } else {
        $("#time-2").removeClass("complete");
      }
    }
  };

  // profile apis call and states from here
  const profilePop = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      passwordNew: "",
      passwordOld: "",
    },
    validationSchema: profile,
    onSubmit: (values) => {
      updateAdminProfile(values, customer_id, "customer-profile/");
    },
  });

  const [adminName, setAdminName] = useState("");

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const getProfile = async (id, apiLink) => {
    if (id) {
      try {
        const responce = await AdminListService.profileData(id, apiLink);
        if (responce.status === 200) {
          profilePop.setFieldValue("name", responce.data.data.UserName);
          profilePop.setFieldValue("email", responce.data.data.Email);
          setAdminName(responce.data.data.AdminName);
        }
      } catch (err) {
        console.log(err);

        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message, {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("Something went wrong!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };

  const updateAdminProfile = async (values, id, linkApi) => {
    setPageLoading(true);
    const dropArea = document.querySelector("#exampleExtraLargeModal"),
      btn = dropArea.querySelector(".btn-close");
    try {
      const responce = await AdminListService.profileUpdate(
        values,
        id,
        linkApi
      );

      if (responce.status === 200) {
        setPageLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        btn.click();
      }
    } catch (err) {
      console.log(err);
      setPageLoading(false);

      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.error(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong!", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const logOut = async (e) => {
    e.preventDefault();
    await userActions.logOut("customerLogOut");
    navigate("/admin/customer", { replace: true });
  };

  useEffect(() => {
    if (customer_id) {
      getOsr("load");
      getProfile(customer_id, "customer-profile/");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div className="single-admin">
      <header>
        <div className="container">
          <img src={Logo} alt="new-logo" />

          <div className="profile">
            <div className="user">
              <h3>{adminName}</h3>

              <img
                src={logOutIco}
                alt="profile icon"
                data-bs-toggle="modal"
                data-bs-target="#exampleExtraLargeModal"
                type="button"
                // onClick={() => {
                //   $(".menu").toggleClass("active");
                // }}
              />
              <img
                src={profileIco}
                className="logout"
                alt="profile icon"
                onClick={logOut}
              />
            </div>
            {/* <div className="menu">
              <ul>
                <li>
                  <a
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleExtraLargeModal"
                    href="/"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-person"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                    </svg>
                    Profile
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </header>
      <div className="container">
        <div className="form-md">
          <div className="uk-timeline">
            <div className="uk-timeline-item active">
              <div className="uk-timeline-icon invisible">
                <span className="uk-badge">1</span>
              </div>
              <div className="uk-timeline-content">
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header">
                      <div
                        className="collapsed card-header"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        type="button"
                        aria-controls="collapseOne"
                      >
                        Edit Email Template
                      </div>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse border-0"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body p-0 border-0">
                        <NewNewsletter />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-timeline-item active" id="time-1">
              <div className="uk-timeline-icon">
                <span className="uk-badge">1</span>
              </div>
              <div className="uk-timeline-content">
                {/* step 1  */}
                <div className="card">
                  <div className="card-header">
                    {isEditable ? "Update" : "Add"} a Customer
                  </div>
                  <div className="card-body">
                    <form
                      className="row g-3"
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <label className="form-label">
                            First Name{" "}
                            <span className="gl-form-asterisk"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="fname"
                            value={values.fname || ""}
                            onChange={handleChange}
                          />
                          {errors.fname && touched.fname ? (
                            <span className="text-danger">{errors.fname}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <label className="form-label">
                            Last Name <span className="gl-form-asterisk"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="lname"
                            value={values.lname || ""}
                            onChange={handleChange}
                          />
                          {errors.lname && touched.lname ? (
                            <span className="text-danger">{errors.lname}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <label className="form-label">
                            Address <span className="gl-form-asterisk"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            name="address"
                            value={values.address || ""}
                            onChange={handleChange}
                          />
                          {errors.address && touched.address ? (
                            <span className="text-danger">
                              {errors.address}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <label className="form-label">
                            City <span className="gl-form-asterisk"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            name="city"
                            value={values.city || ""}
                            onChange={handleChange}
                          />
                          {errors.city && touched.city ? (
                            <span className="text-danger">{errors.city}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group select2-plug">
                          <label className="form-label">
                            State <span className="gl-form-asterisk"></span>
                          </label>

                          <select
                            className="form-select"
                            name="state"
                            value={values.state || ""}
                            // onChange={handleChange}
                            //
                            ref={selectRef}
                          >
                            <option value="" disabled={true}>
                              Select State
                            </option>
                            {StatesList.map((val, index) => (
                              <option key={index} value={val.value}>
                                {val.label}
                              </option>
                            ))}
                          </select>

                          {errors.state && touched.state ? (
                            <span className="text-danger">{errors.state}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <label className="form-label">
                            Zip Code<span className="gl-form-asterisk"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Zip Code"
                            name="zip"
                            value={values.zip || ""}
                            onChange={handleChange}
                          />
                          {errors.zip && touched.zip ? (
                            <span className="text-danger">{errors.zip}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <label className="form-label">
                            Phone Number
                            {/* <span className="gl-form-asterisk"></span> */}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            name="phone"
                            value={values.phone || ""}
                            onChange={handleChange}
                          />
                          {errors.phone && touched.phone ? (
                            <span className="text-danger">{errors.phone}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <label className="form-label">
                            Email <span className="gl-form-asterisk"></span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value={values.email || ""}
                            onChange={(e) => {
                              handleChange(e);
                              // varifyEmail(e);
                            }}
                          />
                          {errors.email && touched.email ? (
                            <span className="text-danger">{errors.email}</span>
                          ) : emailError !== "" ? (
                            <span className="text-danger">{emailError}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <label className="form-label">
                            Denomination{" "}
                            <span className="gl-form-asterisk"></span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Denomination"
                            name="denomination"
                            value={values.denomination || ""}
                            onChange={handleChange}
                          />
                          {errors.denomination && touched.denomination ? (
                            <span className="text-danger">
                              {errors.denomination}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <label className="form-label">
                            Voucher Number{" "}
                            <span className="gl-form-asterisk"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Voucher Number"
                            name="voucher"
                            value={values.voucher || ""}
                            onChange={(e) => {
                              handleChange(e);
                              voucherCheck(e.target.value);
                            }}
                            maxLength="6"
                          />
                          {errors.voucher && touched.voucher ? (
                            <span className="text-danger">
                              {errors.voucher}
                            </span>
                          ) : voucherError !== "" ? (
                            <span className="text-danger">{voucherError}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <button
                          className="btn btn-primary form-btn"
                          type="submit"
                          // id="add-cus"
                          disabled={EmailChecking}
                        >
                          {EmailChecking ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            ></div>
                          ) : null}
                          {isEditable ? "Update Data" : "Add Data"}
                        </button>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <button
                          className="btn btn-primary reset-btn"
                          type="button"
                          onClick={() => {
                            if (values.state !== "") {
                              $(selectRef.current).select2("destroy");
                              setTimeout(() => {
                                $(selectRef.current).select2();
                              }, 200);
                            }
                            resetForm();
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </form>

                    <div className="or"></div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-7 col-lg-9 d-flex align-items-center">
                            <h4 className="inner-heading mb-0">
                              Upload Multiple Customers
                            </h4>
                          </div>
                          <div className="col-md-5 col-lg-3">
                            <a
                              className="btn btn-primary form-btn d-flex align-items-center justify-content-center mt-0"
                              href="/assets/csv/sample-sheet-new.xlsx"
                            >
                              Download Sample Sheet
                            </a>
                            {/* <a
                              href="/assets/csv/sample-sheet.xlsx"
                              className="sample-sheet-btn"
                            >
                              <img src={sampleIcon} alt="sample sheet" />
                              Download Sample Sheet
                            </a> */}
                          </div>
                        </div>

                        <div
                          // className={`drop_box ${
                          //   loading === "0%" ? "w-100" : ""
                          // }`}
                          className="drop_box w-100"
                          // onClick={() => {
                          //   const dropArea =
                          //       document.querySelector(".drop_box"),
                          //     input = dropArea.querySelector("input");

                          //   input.click();
                          // }}
                        >
                          <img
                            src={fileImg}
                            className="file-icon"
                            alt="missing"
                          />

                          <p>Select (.CSV, .XLS, .XLSX) File</p>
                          <input
                            type="file"
                            accept=".xlsx,.xls,.csv"
                            id="fileID"
                            onChange={putCustomers}
                            onClick={(e) => {
                              e.target.value = null;
                              setLoading("0%");
                            }}
                          />
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                              const dropArea =
                                  document.querySelector(".drop_box"),
                                input = dropArea.querySelector("input");

                              input.click();
                            }}
                          >
                            <img src={browse} alt="missing" />
                            Browse
                          </button>
                        </div>
                        {loading !== "0%" ? (
                          fileStatus === "" ? (
                            fileError ? (
                              <div className="progress-file mt-2">
                                <div className="col-lg-12">
                                  <div className="alert alert-danger mb-2 p-2 rounded-1">
                                    <b className="fw-bold">Error! </b> Please
                                    Select File
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="progress-file">
                                <h6>Uploading</h6>
                                <p>
                                  {file.name}{" "}
                                  <span>{Math.round(file.size / 1024)}KB</span>
                                  {/* <button className="btn btn-link">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-x"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                    </svg>
                                  </button> */}
                                </p>
                                <div className="clearfix"></div>
                                <div
                                  className="progress"
                                  role="progressbar"
                                  aria-label="Basic example"
                                  aria-valuenow="100"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <div
                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                    role="progressbar"
                                    aria-valuenow="75"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: loading }}
                                  ></div>
                                </div>
                                <div className="done-perc">
                                  {loading} Done
                                  {/* <span>120kb / sec</span> */}
                                </div>
                                <div className="clearfix"></div>
                              </div>
                            )
                          ) : fileStatus === "ok" ? (
                            <></>
                          ) : (
                            // <div className="progress-file mt-2">
                            //   <div className="col-lg-12">
                            //     <div className="alert alert-success alert-dismissible fade show mb-2 p-2 rounded-1">
                            //       Uploaded Successfully!
                            //       <button
                            //         type="button"
                            //         className="btn-close p-2"
                            //         data-bs-dismiss="alert"
                            //         aria-label="Close"
                            //       ></button>
                            //     </div>
                            //   </div>
                            // </div>
                            <div className="progress-file mt-2">
                              <div className="row">
                                {invalidEmails.length
                                  ? invalidEmails.map((res, index) => {
                                      return (
                                        <div className="col-lg-6">
                                          <div
                                            className="alert alert-danger alert-dismissible fade show  mb-2 p-2 rounded-1"
                                            key={index}
                                          >
                                            <strong className="fw-bold">
                                              Invalid Emails:{" "}
                                            </strong>
                                            {res.errors}
                                            <button
                                              type="button"
                                              className="btn-close p-2"
                                              data-bs-dismiss="alert"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : null}

                                {errorData.length
                                  ? errorData.map((res, index) => {
                                      return (
                                        <div className="col-lg-6">
                                          <div
                                            className="alert alert-danger alert-dismissible fade show mb-2 p-2 rounded-1"
                                            key={index}
                                          >
                                            <b className="fw-bold">Note!</b>{" "}
                                            Problem in row number {res.row} ,
                                            <b className="fw-bold">Error! </b>
                                            {res.errors}
                                            <button
                                              type="button"
                                              className="btn-close p-2"
                                              data-bs-dismiss="alert"
                                              aria-label="Close"
                                            ></button>
                                          </div>{" "}
                                        </div>
                                      );
                                    })
                                  : null}
                                <div className="col-lg-6">
                                  <Duplicate data={duplicateMsg} />
                                </div>
                              </div>
                            </div>
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div id="customer-list-data">
                  {customerLoading ? (
                    <Skeleton count={5} height="48px" />
                  ) : UserData.length ? (
                    <div
                      className="row"
                      // id="customer-list"
                    >
                      <div className="col-lg-12 mb-4">
                        <div className="mt-3 with-manage">
                          <div className="question-heading ">
                            Manage SYC Users
                          </div>
                          <div className="yes-no">
                            <button
                              type="button"
                              className="btn btn-primary active"
                              onClick={delSelected}
                            >
                              Delete Selected
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary active"
                              onClick={delDataAll}
                            >
                              Delete All
                            </button>
                            <button
                              type="button"
                              id="complete-1"
                              className="btn btn-primary active"
                              onClick={() => {
                                submitCustomerPop();
                              }}
                            >
                              Submit
                            </button>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                        <div className="table-responsive mt-3">
                          <table className="table table-striped align-middle ">
                            <thead className="table-dark">
                              <tr>
                                <th>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input slect_all"
                                      type="checkbox"
                                      onChange={allSelect}
                                    />
                                  </div>
                                </th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                {/* <th>Address</th> */}
                                <th>City</th>
                                <th>State</th>
                                <th>Zip</th>
                                <th>Phone No</th>
                                <th>Email</th>
                                <th>Denomination </th>
                                <th>Voucher No</th>

                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {UserData.map((el, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="form-check ">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={el.UserId}
                                      />
                                    </div>
                                  </td>
                                  <td>{el.FirstName}</td>
                                  <td>{el.LastName}</td>
                                  {/* <td>{el.Address}</td> */}
                                  <td>{el.City}</td>
                                  <td>{el.State}</td>
                                  <td>{el.ZipCode}</td>
                                  <td>{el.PhoneNumber}</td>
                                  <td>{el.Email}</td>
                                  <td>{el.Denomination}</td>
                                  <td>
                                    {el.voucher === "" ? "N/A" : el.voucher}
                                  </td>

                                  <td>
                                    <button
                                      className="btn p-0 btn-sm border-0"
                                      type="button"
                                      onClick={() => {
                                        setEmailError("");
                                        getCustomerWithId(
                                          el.UserId,
                                          "customer"
                                        );
                                      }}
                                    >
                                      <img
                                        src={edit}
                                        className="edit"
                                        alt="missing"
                                      />
                                    </button>
                                    <button
                                      className="btn btn-sm ms-2 border-0 p-0"
                                      type="button"
                                      onClick={() => {
                                        delData(el.UserId, "customer");
                                      }}
                                    >
                                      <img
                                        src={del}
                                        className="deleted"
                                        alt="missing"
                                      />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        {totalResults > limit && totalPages > 1 ? (
                          <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={parseInt(limit)}
                            totalItemsCount={totalResults}
                            className="justify-content-center"
                            onChange={(e) => {
                              getUsers(e);
                            }}
                            pageRangeDisplayed={8}
                            itemClass="page-item"
                            linkClass="page-link"
                            firstPageText="First Page"
                            lastPageText="Last Page"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="uk-timeline-item" id="time-2">
              {osrData.length ? (
                <>
                  <div className="uk-timeline-icon">
                    <span className="uk-badge">2</span>
                  </div>

                  <NewOsrIndex
                    osrData={osrData}
                    osrLoading={osrLoading}
                    setOsrLoading={setOsrLoading}
                    getOsr={getOsr}
                    getCustomerWithId={getCustomerWithId}
                    delData={delData}
                    getAccountant={getAccountant}
                    osr_id={customer_id}
                  />
                </>
              ) : null}
            </div>

            <div className="uk-timeline-item" id="time-3">
              {accountantData.length ? (
                <>
                  <div className="uk-timeline-icon">
                    <span className="uk-badge">3</span>
                  </div>
                  <NewAccountantIndex
                    accountantData={accountantData}
                    accountantLoading={accountantLoading}
                    setAccountantLoading={setAccountantLoading}
                    getAccountant={getAccountant}
                    getCustomerWithId={getCustomerWithId}
                    delData={delData}
                    accountant_id={customer_id}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleExtraLargeModal"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Change Information</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body form-md mt-0">
              <form onSubmit={profilePop.handleSubmit} noValidate>
                <div className="row">
                  <div className="col-12 col-md-4 ">
                    <div className="form-group">
                      <label className="form-label">Account Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Account Name"
                        disabled={true}
                        name="name"
                        value={profilePop.values.name || ""}
                        onChange={profilePop.handleChange}
                      />
                      {profilePop.errors.name && profilePop.touched.name ? (
                        <span className="text-danger">
                          {profilePop.errors.name}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        value={profilePop.values.email || ""}
                        onChange={profilePop.handleChange}
                      />
                      {profilePop.errors.email && profilePop.touched.email ? (
                        <span className="text-danger">
                          {profilePop.errors.email}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">Old Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Old Password"
                        name="passwordOld"
                        value={profilePop.values.passwordOld || ""}
                        onChange={profilePop.handleChange}
                      />
                      {profilePop.errors.passwordOld &&
                      profilePop.touched.passwordOld ? (
                        <span className="text-danger">
                          {profilePop.errors.passwordOld}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <div className="form-group">
                      <label className="form-label">New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                        name="password"
                        value={profilePop.values.password || ""}
                        onChange={profilePop.handleChange}
                      />
                      {profilePop.errors.password &&
                      profilePop.touched.password ? (
                        <span className="text-danger">
                          {profilePop.errors.password}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <div className="form-group">
                      <label className="form-label">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm Password"
                        name="passwordNew"
                        value={profilePop.values.passwordNew || ""}
                        onChange={profilePop.handleChange}
                      />
                      {profilePop.errors.passwordNew &&
                      profilePop.touched.passwordNew ? (
                        <span className="text-danger">
                          {profilePop.errors.passwordNew}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <button
                      className="btn btn-primary form-btn w-100"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className={`loader ${pageLoading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
